export default {
	mounted() {
		window.addEventListener('click', this.blurListener);
	},

	beforeDestroy() {
		window.removeEventListener('click', this.blurListener);
	},

	methods: {
		toggleTableMenu(index) {
			this.lists = this.lists.map((list, i) => ({
				...list,
				showOption: index === i ? !list.showOption : false,
			}));
		},

		blurListener(e) {
			const isThisComponent =
				e.target.parentNode?._prevClass === 'menu-activator' ||
				e.target._prevClass === 'menu-activator';

			if (!isThisComponent) {
				this.lists = this.lists.map(item => ({
					...item,
					showOption: false,
				}));
			}
		},
	},
};
