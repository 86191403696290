<template>
	<div class="role-management-members">
		<bg-grid class="mb-24">
			<bg-grid-item :col="12">
				<div class="back-button" @click="handleBack"><bg-icon name="back" size="lg" /></div>
				<bg-text size="heading-3" class="page-title">
					Member Management
				</bg-text>
			</bg-grid-item>

			<bg-grid-item :col="6" class="mb-0">
				<bg-grid>
					<bg-grid-item :col="8" class="mb-0">
						<bg-search-bar
							v-model="search"
							placeholder="Cari berdasarkan nama role"
						/>
					</bg-grid-item>
					<bg-grid-item :col="3" class="mb-0">
						<bg-button size="lg" block> Cari </bg-button>
					</bg-grid-item>
				</bg-grid>
			</bg-grid-item>

		</bg-grid>

		<table class="ss-table">
			<thead>
				<tr>
					<th
						v-for="(header, i) in headers"
						role="columnheader"
						:aria-label="header.text"
						:key="`header-${i}`"
						:class="header.classes"
					>
						<bg-text size="title-5">{{ header.text }}</bg-text>
					</th>
				</tr>
			</thead>

			<tbody v-if="loadingList">
				<tr>
					<td><skeleton width="100%" height="24px" /></td>
					<td><skeleton width="100%" height="24px" /></td>
					<td><skeleton width="100%" height="24px" /></td>
					<td><skeleton width="100%" height="24px" /></td>
				</tr>
				<tr>
					<td><skeleton width="100%" height="24px" /></td>
					<td><skeleton width="100%" height="24px" /></td>
					<td><skeleton width="100%" height="24px" /></td>
					<td><skeleton width="100%" height="24px" /></td>
				</tr>
			</tbody>

			<tbody v-else>
				<tr v-for="(list, i) in lists" :key="`list-${i}`">
					<td>
						{{ list.name }}
					</td>
					<td>
						{{ list.email }}
					</td>
					<td>
						{{ list.role }}
					</td>
					<td class="ta-c">
						<div class="ss-table__menu">
							<div
								role="button"
								class="menu-activator"
								@click="toggleTableMenu(i)"
							>
								<bg-icon
									name="more-vertical"
									title="open options for this item"
								/>
							</div>
							<div v-if="list.showOption" class="menu-options position-center">
								<bg-list-item clickable>Edit</bg-list-item>
								<bg-list-item clickable>Atur Member</bg-list-item>
								<bg-list-item clickable>Hapus</bg-list-item>
							</div>
						</div>
					</td>
				</tr>
			</tbody>
		</table>

		<div class="no-data" v-if="!lists.length && !loadingList">
			<bg-text size="title-2">Data Tidak Ditemukan</bg-text>
			<bg-text size="body-2">Data tidak ditemukan di filter atau kata kunci yang Anda gunakan.</bg-text>
		</div>

		<bg-pagination
			v-model="pagination.page"
			:page-total="pagination.total_data"
			v-if="lists.length && !loadingList"
		/>
	</div>
</template>

<script>
import {
	BgGrid,
	BgGridItem,
	BgButton,
	BgSearchBar,
	BgText,
	BgIcon,
	BgListItem,
	BgPagination,
	BgSkeleton
} from 'bangul-vue';

import tableMenuHandler from '@admin/mixins/tableMenuHandler';

export default {
	name: 'RoleManagementMembers',

	components: {
		BgGrid,
		BgGridItem,
		BgButton,
		BgSearchBar,
		BgText,
		BgIcon,
		BgListItem,
		BgPagination,
		skeleton: BgSkeleton
	},

	mixins: [tableMenuHandler],

	data() {
		return {
			search: '',
			loadingList: false,
			headers: [
				{
					text: 'Nama',
					classes: 'ta-l',
				},
				{
					text: 'Email',
					classes: 'ta-l',
				},
				{
					text: 'Role',
					classes: 'ta-l',
				},
				{
					text: 'Action',
				},
			],
			lists: [
				{
					name: 'Long James',
					email: 'sweet17@hotmail.com',
					role: 'Admin',
					showOption: false,
				},
			],
			pagination: {
				page: 1,
				total_data: 10,
			},
		}
	},

	methods: {
		handleBack() {
			this.$router.push({ name: 'role-management' })
		}
	}
}
</script>

<style lang="scss" scoped src="./RoleManagementMembers.scss"></style>